import Banner from "../containers/WebApp/Solutions/Banner";
import Section from "../containers/WebApp/Solutions/Section";
import SEO from "../components/SEO";
import React from "react";

const Solutions = () => {
  return (
    <>
      <SEO title="Solutions" />
      <Banner />
      <Section />
    </>
  );
};

export default Solutions;
