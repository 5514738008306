import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_CONTACT } from "../../../../common/data/WebApp";
import React, { useRef, useState } from "react";
import SectionArea, {
  Row,
  Col,
  Input,
  TextArea,
  SubmitButton,
  ContactFormContainer,
  Form,
} from "./section.style";

const ContactForm = () => {
  const { sections } = SECTION_CONTACT;
  const [open, setOpen] = React.useState(false);
  const form = useRef();

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  return (
    <SectionArea>
      <Container>
        <Row className="rowContents">
          <Box className="blockTitle">
            <Heading as="h2" content="Get in touch with us" />
            <Text
              as="p"
              content="To learn more about our solutions and services, or how we can help your business - get in touch with us."
            />
          </Box>

          <Box className="blockTitle">
            <ContactFormContainer>
              <Form>
                <form
                  action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                  method="POST"
                >
                  <input type="hidden" name="oid" value="00D2w00000GlCo7" />
                  <input
                    type="hidden"
                    name="retURL"
                    value="https://www.x-rd.com.au/register/"
                  />
                  <Row>
                    <div className="form-group">
                      <Input
                        type="text"
                        name="first_name"
                        className="form-control"
                        id="first_name"
                        placeholder="Enter your first name"
                        required="required"
                      />
                    </div>
                    <div className="form-group">
                      <Input
                        type="text"
                        name="last_name"
                        className="form-control"
                        id="last_name"
                        placeholder="Enter your last name"
                        required="required"
                      />
                    </div>
                  </Row>

                  <Row>
                    <div className="form-group">
                      <Input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="form-group">
                      <Input
                        type="text"
                        name="city"
                        className="form-control"
                        id="city"
                        placeholder="City"
                        required="required"
                      />
                    </div>
                  </Row>

                  <Row>
                    <div className="form-group">
                      <Input
                        type="text"
                        name="company"
                        className="form-control"
                        id="company"
                        placeholder="Company Name"
                        required="required"
                      />
                    </div>
                  </Row>

                  <Row>
                    <SubmitButton
                      name="submit"
                      type="submit"
                      disabled={serverState.submitting}
                    >
                      Get in touch
                    </SubmitButton>
                  </Row>

                  {serverState.status && (
                    <p className={!serverState.status.ok ? "errorMsg" : ""}>
                      {serverState.status.msg}
                    </p>
                  )}
                </form>
              </Form>
            </ContactFormContainer>
          </Box>
        </Row>
      </Container>
    </SectionArea>
  );
};

export default ContactForm;
