import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_SOLUTIONS } from "../../../../common/data/WebApp";
import { Link } from "gatsby";
import ContactForm from "../../ContactForm/Section";
import React from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import SectionArea, { Row, ColumnContents } from "./section.style";
import Product1 from "../../../../common/assets/image/webApp/product1.svg";
import Product2 from "../../../../common/assets/image/webApp/product2.svg";
import Product3 from "../../../../common/assets/image/webApp/product3.svg";
import Video from "../../../../components/video";

const SectionA = () => {
  const { sections } = SECTION_SOLUTIONS;
  // const { title, text, button } = blockTitle;
  return (
    <ColumnContents>
      <SectionArea
        style={{ backgroundColor: "white", paddingBottom: "80px" }}
        id="secd3vdevops"
      >
        <Container>
          <Row className="rowContents">
            <Box className="blockTitle">
              <img className="productImage" src={Product1} alt="product-logo" />
              <Text as="p" content={sections[0].text} />
              <Link className="button" to={sections[0].button.link}>
                <span>
                  {sections[0].button.label}
                  <Icon icon={androidArrowForward} size={16} />
                </span>
              </Link>
            </Box>
            <Box className="postsWrap">
              <Box className="bannerVideo">
                <Video
                  videoSrcURL="https://www.youtube.com/embed/moVPpNdgxx4?si=8VT1_x9PMjkyPxwi"
                  videoTitle="secd3v"
                />
              </Box>
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <SectionArea
        style={{ backgroundColor: "#F4F4F4", paddingBottom: "80px" }}
        id="s4nctum"
      >
        <Container>
          <Row className="rowContents">
            <Box className="blockTitle">
              <img className="productImage" src={Product2} alt="product-logo" />
              <Text as="p" content={sections[1].text} />

              <span style={{ color: "#878787", fontWeight: "bold" }}>
                Coming Soon
              </span>
            </Box>
            <Box className="blockTitle">
              <img className="productImage" src={Product3} alt="product-logo" />
              <Text as="p" content={sections[2].text} />
              <Link className="button" to={sections[2].button.link}>
                <span>
                  {sections[2].button.label}
                  <Icon icon={androidArrowForward} size={16} />
                </span>
              </Link>
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <ContactForm />
    </ColumnContents>
  );
};

export default SectionA;
